import * as React from "react";
import { Component } from "react";
import { hot } from "react-hot-loader";

import "./App.css";

import Layout from "./components/layout/Layout"
import NavBar from "./components/nav-bar/NavBar"

function App() {
  return (
    <div className="App">
      <Layout></Layout>
      <NavBar></NavBar>
      <h1>Hello, Universe!</h1>
    </div>
  );
}

export default hot(module)(App);